.main-bg--search {
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.9;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;

    .main-search {
        height: 85vh;
        display: flex;
        align-items: center;

    }

    .search-icon {
        font-size: 70px;
        margin-top: 12px;
        color: white;
        opacity: 0.3;
    }

    input {
        background: none;
        height: 100px;
        font-size: 60px;

        &:focus {
            background: none;
        }

        &::placeholder {
            font-size: 70px;
            color: white;
            opacity: 0.3;
        }
    }


    .search-close--badge {
        width: 28px;
        height: 28px;
        background-color: #252328;
        align-items: center;
        transition: all 0.3s;

        &:hover {
            transform: translateY(-3px);

        }
    }
}

@media (min-width:576px) and (max-width:768px) {
    .main-bg--search {
        input {

            &::placeholder {
                font-size: 50px;
            }
        }

        .search-icon {
            font-size: 50px;
            margin-top: 30px;

        }

    }
}

@media (max-width:576px) {
    .main-bg--search {
        input {
            height: auto;
            font-size: 40px;

            &::placeholder {
                font-size: 40px;
            }
        }

        .search-icon {
            font-size: 40px;
            margin-top: 17px;

        }

    }
}