.component {
    width: 100%;
    height: 80vh;

    p {
        font-size: 16px;
        width: 60%;
    }

    .info {
        height: 80vh;
        display: flex;
        align-items: center;
    }

    .title {
        width: 80%;
        font-size: 4.5rem;
    }

    .image {
        height: 80vh;
        display: flex;
        align-items: center;

       
    }
}


@media (min-width:876px) and (max-width:1200px) {
    .component {
        .title {
            width: 90%;
            font-size: 4rem;
        }

        p {
            font-size: 15px;
            width: 70%;
        }

        .image {
            height: 100vh;
            display: flex;
            align-items: center;

        }
    }
}


@media (min-width:768px) and (max-width:876px) {
    .component {
        .title {
            width: 80%;
            font-size: 3rem;
        }

        p {
            font-size: 15px;
            width: 75%;

        }

        .image {
            height: 100vh;
            display: flex;
            align-items: center;

        }
    }
}



@media (max-width:768px) {
    .component {
        height: auto;
        padding-bottom: 30px;

        .title {
            width: 100%;
            font-size: 3rem;
        }

        .info {
            height: auto;

        }

        p {
            font-size: 15px;
            width: 80%;
        }

        .image {
            height: auto;

        }
    }
}

@media (max-width:576px) {
    .component {
        height: auto;
        padding-bottom: 30px;

        .title {
            width: 90%;
            font-size: 2.75rem;
        }

        .info {
            height: auto;

        }

        p {
            font-size: 15px;
            width: 100%;
        }

        .image {
            height: auto;


        }
    }
}