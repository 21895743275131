._footer {
    .card {
        .card-body {
            padding: 80px 0px;
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            .footer-navs {
                ul li {
                    padding-top: 7px;

                    &:hover {
                        a {
                            color: black;
                        }
                    }

                    a {
                        font-size: 12px;
                        color: #868690;
                    }
                }
            }
        }

        .card-footer {
            span {
                color: #868690;
                font-size: 12px;
            }

            .social-media {
                .social-icon {
                    font-size: 16px;
                }
            }
        }
    }
}

@media (min-width:768px) and (max-width:1199.98px) {
    ._footer {
        .card {
            .card-body {
                padding: 50px 0px;
                grid-template-columns: repeat(4, 1fr);

            }
        }
    }
}

@media (min-width:576px) and (max-width:767.98px) {
    ._footer {
        .card {
            .card-body {
                padding: 40px 0px;
                grid-template-columns: repeat(2, 1fr);

            }

        }
    }
}

@media (max-width:575.98px) {
    ._footer {
        .card {
            .card-body {
                padding: 30px 0px;
                grid-template-columns: repeat(1, 1fr);

            }

            .card-footer {
                span {
                    color: #868690;
                    font-size: 11px;
                }

            }
        }
    }
}