.review {

    padding: 30px 0px;

    img {
        width: 110px;
        border: 5px solid white;
    }
}

@media (min-width:996px) and (max-width:1200px) {
    .review {

        h4 {
            font-size: 17px;

        }
    }
}

@media (min-width:576px) and (max-width:995.99px) {
    .review {

        h4 {
            font-size: 15px;

        }

        span {
            font-size: 11px;
        }
    }
}

@media (max-width:576px) {
    .review {
        h4 {
            font-size: 14px;
        }

        span {
            font-size: 11px;
        }
    }
}