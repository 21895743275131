.rol{
    width: 100%;
    height: 90vh;
    .carousel-imgs{
        height: 90vh;
        display: flex;
        align-items: center;
     }
     .desc {
        font-size: 16px;
        width:60%;
    }

 
    .info {
        height: 90vh;
        display: flex;     
        align-items: center;
    }

    .title {
        width: 80%;
        font-size: 4.5rem;
    }
}




@media (min-width:876px) and (max-width:1200px) {
    .rol{
        .title {
            width: 90%;
            font-size: 4rem;
        }
        .desc {
            font-size: 15px;
            width:70%;
        }
        
    }
}


@media (min-width:768px) and (max-width:876px) {
    .rol{
        .title {
            width: 80%;
            font-size: 3rem;
        }
        .desc {
            font-size: 15px;
            width: 75%;
            
        }
      
    }
}



@media (max-width:768px) {
    .rol{
        height: auto;
        padding-bottom: 30px;
        .title {
            width: 100%;
            font-size: 3rem;
        }
        .info {
            height:auto;
            margin-top: 80px;
            
        }
        .desc{
            font-size: 15px;
            width: 80%;
        }
       
    }
}

@media (max-width:576px) {
    .rol{
        height: auto;
        padding-bottom: 30px;
        .title {
            width: 90%;
            font-size: 2.75rem;
        }
        .info {
            height:auto;
            margin-top: 80px;
            
        }
        .desc {
            font-size: 15px;
            width: 100%;
        }
       
    }
}
