header {
.header-comapny--logo{
    img {
        height: 35px;
    }
}
   
    
    .toggle-button{
        font-size:20px;
    }
    nav ul li{
        font-size: 15px;
        a{
            color: black;
            transition: all 0.3s;
            &:hover{
                color: #e88838;
            }
        }
    }
}

@media (max-width:1200px) {

    .desktop-nav {
        height:70px;
        overflow: hidden;
    }

    .mobile-nav{
        height: 450px;
    }

    nav ul {
        height: 330px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        justify-content: center;
    }
}