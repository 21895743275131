.enter-prise {
    width: 100%;
    height: 90vh;
    p {
        font-size: 16px;
        width:65%;
    }

    .info {
        height: 90vh;
        display: flex;
        align-items: center;
    }

    .title {
        width: 80%;
        font-size: 4.5rem;
    }
    .enter-prise--logo{
      height:90vh;
      display: flex;
      align-items: center;
    }
}

@media (min-width:876px) and (max-width:1200px) {
    .enter-prise{
        .title {
            width: 90%;
            font-size: 4rem;
        }
        p {
            font-size: 15px;
            width:70%;
        }
        .image{
            height: 100vh;
            display: flex;
            align-items: center;
           
        }
    }
}


@media (min-width:768px) and (max-width:876px) {
    .enter-prise{
        .title {
            width: 80%;
            font-size: 3rem;
        }
        p {
            font-size: 15px;
            width: 75%;
            
        }
        .image{
            height: 100vh;
            display: flex;
            align-items: center;
          
        }
    }
}



@media (max-width:768px) {
    .enter-prise{
        height: auto;
        padding-bottom: 30px;
        .title {
            width: 100%;
            font-size: 3rem;
        }
        .info {
            height:auto;

        }
        p {
            font-size: 15px;
            width: 80%;
        }
        .enter-prise--logo{
            height: auto;
        
        }
    }
}

@media (max-width:576px) {
    .enter-prise{
        height: auto;
        padding-bottom: 30px;
        .title {
            width: 90%;
            font-size: 2.75rem;
        }
        .info {
            height:auto;

        }
        p {
            font-size: 15px;
            width: 100%;
        }
        .enter-prise--logo{
            height: auto;
        
           
        }
    }
}
