.faqs {
    padding-top: 7rem;

    h1 {
        font-size: 4.5rem;
        padding-bottom: 40px;
    }

    .tabs {

        &:hover .opacity-25 {
            opacity: 0.6 !important;
        }

        .badge {
            width: 25px;
            height: 25px;
            align-items: center;
            font-size: 12px;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.5;
            letter-spacing: 0;
        }
    }

    img {
        box-shadow: 0 10px 14px 0 rgba(23, 43, 77, .2);
        transition: all 0.5s ease-in-out;
        // transform: scaleX(0.9);
    }
}


@media (min-width:996px) and (max-width:1199px) {
   .faqs{
    h1{
        font-size: 4rem !important;
            padding-bottom: 35px;
    }
   }
}

@media (min-width:876px) and (max-width:996px) {
    .faqs{
        padding-top: 4rem;
     h1{
         font-size: 2.5rem !important;
             padding-bottom: 15px;
     }
    }
 }

 @media (min-width:576px) and (max-width:876px) {
    .faqs{
        padding-top: 4rem;

     h1{
         font-size: 2.2rem !important;
             padding-bottom: 15px;
     }
    }
 }

 @media (max-width:576px) {
    .faqs{
        padding-top: 3rem;

     h1{
         font-size: 2rem !important;
             padding-bottom: 10px;
     }
    }
 }