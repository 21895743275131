//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}
.btn-custom {
	padding: 10px 0px;
}
input::placeholder{
	font-size: 11px;
}
.btn-transparent {
	background-color:transparent !important;
}
ul{
	padding-left: 0px;
    margin-bottom: 0px;
}
li{
	list-style-type: none;
}
.text-light--red{
	color: #ff6d56;
}
*{
	box-sizing: border-box;
}

