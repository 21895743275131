.typewrite {
    width: 100%;
    height: auto;
    margin-bottom: 30px;

    p {
        font-size: 1.6875rem;
        line-height: 2.3rem;

    }

    h1 {
        font-size: 80px;
        padding: 60px 0px;
    }

    button {
        font-size: 15px;
    }
}

@media (max-width:1199px) {
    .typewrite {
        h1 {
            font-size: 65px;
        }

        p {
            font-size: 1.3000rem;
            padding-top: 15px;
            font-weight: 500;
        }
    }
}

@media (max-width:996px) {
    .typewrite {
        h1 {
            font-size: 60px;
            text-align: center;
        }

        p {
            font-size: 1.2rem;
        }

        .more {
            margin-top: 40px;
        }


    }
}

@media (max-width:767px) {
    .typewrite {
        h1 {
            font-size: 60px;
            text-align: center;
            padding-bottom: 30px;

        }

        p {
            font-size: 1.2rem;
            text-align: center;
        }

        .more {
            margin-top: 0px;
        }
    }
}

@media (max-width:576px) {
    .typewrite {
        h1 {
            font-size: 55px;
            text-align: center;
            padding-bottom: 30px;

        }

        p {

            line-height: 2rem;

        }
    }
}