.demo {
    height: auto;
    padding: 50px 0px;
    background-color: #252328;
    .meetBook {
        display: flex;
        justify-content: center;
    }

    .top-content {
        .title {
            font-size: 50px;
        }

        p {
            color: #c6c7cc;
            font-size: 14px;
        }

    }
    .meet-book{
        .badge{
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
           
        }
    }
   
}
.demo-bottom{
    span {
        color: #868690;
        font-size: 12px;
    }
}


@media (min-width:1200px) {

    .demo {
        height: 100vh;
        padding: 0px 0px;


        .meetBook {
            height: 100vh;
            display: flex;
            align-items: center;
        }
    }
}


@media (max-width:1199px) {
    .demo {
        .top-content {
            .title {
                font-size: 30px;
            }
        }
    }
}

@media (max-width:576px) {
    .demo {
        .meet-book--title{
          font-size: 17px;
        }
    }
    .demo-bottom{
        span {
            font-size: 11px;
        }
    }
}