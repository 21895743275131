.brand-logo{
    ._content{
        img{
            height: 90px;
            // width: 100%;
           
            
        }
    }
}

@media (min-width:576px)and (max-width:767px) {
    .brand_title{
        text-align: center;
    }
    .brand-logo{
        text-align: center;
        display: flex;
        justify-content: center;
        ._content{
          width: 80%;
         
        }
    }
}

@media (max-width:576px){
   
    .brand-logo{
        text-align: center;
        display: flex;
        justify-content: center;
        ._content{
          width: 85%;
         
        }
    }
}