.carousel {
    .main-img {
        width: 100%;
        height: 600px;
        position: relative;
    }

    .card {
        width: 260px;
        height: 380px;
        position: absolute;
        border-radius: 5px;
        top: 250px;
        right: -80px;
        z-index: 1 !important;

        .card-img-top {
            display: flex;
            justify-content: center;

            img {
                height: 140px;
            }
        }

        .card-body {
            .card-title {
                font-size: 1.6875rem;
            }

            p {
                font-size: .87rem;
            }
        }
    }

    .pagination {
        width: 100% !important;

        button {
            width: 10px;
            height: 10px;
        }
    }
}




@media (min-width:576px) and (max-width:768px) {
    .carousel {
        .main-img {
            width: 100%;
            height: auto;
            position: relative;

        }

        .card {
            width: 380px;
            height: 330px;
            position:absolute;
            border-radius: 5px;
            z-index: 1 !important;
           left:75px;
           top:450px;


            .card-img-top {
                display: flex;
                justify-content: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                img {
                    width: 100%;
                    height: 140px;
                }
            }

            .card-body {
                .card-title {
                    font-size: 1.5875rem;
                }

                p {
                    font-size: 1rem;
                }
            }
        }

        .pagination {
           margin-top: 180px !important;
            button {
                width: 10px;
                height: 10px;
            }
        }
    }
}


@media (max-width:576px) {
    .carousel {
        .main-img {
            width: 100%;
            height: auto;
            position:unset;

        }

        .card {
            width: 300px;
            height: 400px;
            position:unset;
            border-radius: 5px;
            margin-left: auto;
            margin-right: auto;
            z-index: 1 !important;
            margin-top: -170px;
            .card-img-top {
                display: flex;
                justify-content: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                img {
                    width: 100%;
                    height: 160px;
                }
            }

            .card-body {
                .card-title {
                    font-size: 1.5875rem;
                }

                p {
                    font-size: 1rem;
                }
            }
        }

        .pagination {
            button {
                width: 10px;
                height: 10px;
            }
        }
    }
    
}
