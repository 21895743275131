.solution {
    width: 100%;
    height: 80vh;
    p {
        font-size: 16px;
        width:65%;

    }

    .info {
        height: 80vh;
        display: flex;
        align-items: center;
    }

    .title {
        width: 80%;
        font-size: 4.5rem;
    }
    .fits{
        display: flex;
        height: 80vh;
        align-items: center;

    }

    .card {
        box-shadow: none;
        &:hover {
            transition: all 0.2s;
            box-shadow: -1px 7px 5px 0px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: -1px 7px 5px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: -1px 7px 5px 0px rgba(0, 0, 0, 0.25);
        }

        h4 {
            padding: 20px;
        }

        .more {
            padding: 15px 20px;
        }

        .badge{
         width: 25px;
         height: 25px;
         align-items: center;
         margin-top: -1px;
        }
        
    }

}




@media (min-width:876px) and (max-width:1200px) {
    .solution{
        .title {
            width: 90%;
            font-size: 4rem;
        }
        p {
            font-size: 15px;
            width:70%;
        }
        .fits{
            display: flex;
            height: 100vh;
            align-items: center;
    
        }
    }
}


@media (min-width:768px) and (max-width:876px) {
    .solution{
        .title {
            width: 80%;
            font-size: 3rem;
        }
        p {
            font-size: 15px;
            width: 75%;
            
        }
        .fits{
            display: flex;
            height: 100vh;
            align-items: center;
    
        }
    }
}



@media (max-width:768px) {
    .solution{
        height: auto;
        padding-bottom: 30px;
        .title {
            width: 100%;
            font-size: 3rem;
        }
     
        p {
            font-size: 15px;
            width: 80%;
        }
        .fits{
            height:auto;
        }
        .info {
            height: auto;
        }
      
    }
}

@media (max-width:576px) {
    .solution{
        height: auto;
        padding-bottom: 30px;
        .title {
            width: 90%;
            font-size: 2.75rem;
        }
        .fits{
            height:auto;
        }
        .info {
            height: auto;
        }
        p {
            font-size: 15px;
            width: 100%;
        }
        
    }
}
